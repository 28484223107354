var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.jobRole)?_c('div',[_c('banner',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("headline", { job_role: _vm.jobRole.name }))+" ")]),_c('template',{slot:"header-caption"},[_vm._v(" "+_vm._s(_vm.$t("subheadline"))+" ")])],2),_c('hr',{staticClass:"yellow-rule"}),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.roles),expression:"roles"}]},[_c('v-row',{staticClass:"mt-sm-2"},[_c('v-col',[_c('v-tabs',{attrs:{"center-active":"","color":"secondary","show-arrows":"mobile"}},_vm._l((_vm.roles),function(role){return _c('v-tab',{key:role.id,attrs:{"to":_vm.$i18nRoute({
                name: 'roleShow',
                params: { slug: role.slug }
              }),"replace":""}},[_vm._v(" "+_vm._s(role.name)+" ")])}),1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h6 text-sm-h4 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.$t("featured_companies"))+" ")])]),_vm._l((_vm.featuredCompanies),function(company){return _c('v-col',{key:company.slug,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"company-card d-flex flex-column",attrs:{"to":_vm.$i18nRoute(_vm.companyPath(company)),"height":"100%"}},[_c('v-card-title',{staticClass:"text-subtitle-1 secondary--text font-weight-bold flex-grow-1 align-start"},[_vm._v(" "+_vm._s(company.name)+" ")]),_c('v-card-subtitle',[_c('div',{staticClass:"d-block"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.$t("median")))]),_c('span',{staticClass:"text-subtitle-1 font-weight-bold primary--text"},[_vm._v(_vm._s(_vm.displayCompensationItem(company.median)))])]),_c('div',{staticClass:"d-block"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.$t("compensation_entries")))]),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(_vm._s(company.entries))])])])],1)],1)})],2),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('router-link',{staticClass:"text-subtitle-2",attrs:{"to":_vm.$i18nRoute({
              name: 'companyListing',
              query: { role: _vm.role_slug }
            })}},[_vm._v(" "+_vm._s(_vm.$t("see_companies"))+" "),_c('v-icon',{staticClass:"ml-0",attrs:{"x-small":"","color":"secondary","right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)],1),(_vm.showWorkCircleCards)?[(_vm.locale.code == 'ja')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("posts_in_workcircle")))]),_c('h3',{staticClass:"text-subtitle-2 mr-2"},[_vm._v(" "+_vm._s(_vm.$t("subtitle_for_workcircle"))+" "),_c('a',{staticClass:"text-subtitle-2",attrs:{"href":"https://www.workcircle.app/?ref=opensalary"}},[_vm._v(" "+_vm._s(_vm.$t("join_work_circle"))+" "),_c('v-icon',{staticClass:"ml-0",attrs:{"x-small":"","color":"secondary","right":""}},[_vm._v(" mdi-arrow-right ")])],1)])]),_c('v-col',{staticClass:"overflow-x-auto d-flex mb-4",attrs:{"cols":"12"}},_vm._l((_vm.workCircleThreads),function(thread){return _c('work-circle-card',_vm._b({key:thread.title},'work-circle-card',thread,false))}),1)],1):_vm._e()]:_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.$t("explore_salaries"))+" ")]),_c('h2',{staticClass:"text-subtitle-1 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("explore_salaries_explanation"))+" "),_c('router-link',{staticClass:"text-subtitle-2 companies",attrs:{"to":_vm.$i18nRoute({
                name: 'exploreSalaries',
                query: {
                  role: _vm.role_slug
                }
              })}},[_c('i18n',{attrs:{"path":"see_all_salaries"},scopedSlots:_vm._u([{key:"salary_count",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.salariesCount))])]},proxy:true}],null,false,607205010)}),_c('v-icon',{staticClass:"ml-0",attrs:{"x-small":"","color":"secondary","right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('salary-table',{attrs:{"salaries":_vm.salaries,"loading":_vm.loading,"hide-default-footer":"","options":{ itemsPerPage: 20 }}})],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-subtitle-1 primary--text"},[_c('router-link',{staticClass:"text-subtitle-2 companies",attrs:{"to":_vm.$i18nRoute({
                name: 'exploreSalaries',
                query: {
                  role: _vm.role_slug
                }
              })}},[_c('i18n',{attrs:{"path":"see_all_salaries"},scopedSlots:_vm._u([{key:"salary_count",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.salariesCount))])]},proxy:true}],null,false,607205010)}),_c('v-icon',{staticClass:"ml-0",attrs:{"x-small":"","color":"secondary","right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)])],1),(_vm.onSoftwareEngineer)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.$t("view_by_yoe"))+" ")])])],1):_vm._e(),(_vm.salariesGroupedByYear && _vm.onSoftwareEngineer)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.yearsOfExperienceHeaders,"items":_vm.salariesGroupedByYear,"loading":_vm.loading,"options":{ itemsPerPage: 6 },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.yearsOfExperience",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(item.yearsOfExperience)+" ")])])]}},{key:"item.salaries",fn:function(ref){
              var item = ref.item;
return [_c('horizontal-scatter-chart',{attrs:{"height":_vm.$vuetify.breakpoint.mobile ? 45 : 15,"width":_vm.$vuetify.breakpoint.mobile ? 260 : 100,"min":1500000,"max":30000000,"dataset":item.salaries,"locale":_vm.locale.code}})]}},{key:"item.firstQuartile",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.firstQuartile))+" ")]}},{key:"item.median",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.median))+" ")])]}},{key:"item.thirdQuartile",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.thirdQuartile))+" ")]}}],null,false,1911867456)})],1)],1):_vm._e(),(_vm.onSoftwareEngineer)?_c('v-row',{staticClass:"mb-4"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-subtitle-1 primary--text"},[_c('router-link',{staticClass:"text-subtitle-2 companies",attrs:{"to":_vm.$i18nRoute({
                name: 'yearsOfExperience',
                query: {
                  role: _vm.$route.params.slug
                }
              })}},[_vm._v(" "+_vm._s(_vm.$t("see_all_years"))+" "),_c('v-icon',{staticClass:"ml-0",attrs:{"x-small":"","color":"secondary","right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)])],1):_vm._e(),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"center"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("call_to_add")))])]),_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"secondary","to":_vm.$i18nRoute({ name: 'submitCompensationSelection' }),"large":"","dark":"","rounded":""}},[_c('v-icon',{attrs:{"left":"","dark":"","large":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add_compensation"))+" ")],1)],1),_c('v-row',[_c('v-sheet',{staticClass:"mx-auto",attrs:{"max-width":"600","outlined":"","color":"grey lighten-3"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1"},[_c('v-icon',{attrs:{"large":"","color":"grey"}},[_vm._v(" mdi-email ")]),_vm._v(" "+_vm._s(_vm.$t("subscribe"))+" ")],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("subscribe_subtext")))])],1)],1),_c('v-list-item',[_c('v-col',[_c('v-row',[_c('v-text-field',{staticClass:"mr-1",attrs:{"placeholder":"iamdev@gmail.com","type":"email","outlined":"","clearable":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-btn',{attrs:{"height":"40","dark":"","color":"primary","depressed":""},on:{"click":_vm.createLead}},[_vm._v(" "+_vm._s(_vm.$t("submit"))+" ")])],1)],1)],1)],1)],1)],2),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"accent","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,2552764550),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }