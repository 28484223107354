/**
 * To display a WorkCircle ad card correctly, it requires
 * title: String,
 * threadBody: String,
 * company: String,
 * tags: Array<String>,
 * commentCount: Number,
 * howManyDaysAgo: Number,
 * link: String,
 * author: String,
 */
export const workCircleThreads = [
  {
    title: "テクニカル・コーディングインタビューを任されるには",
    threadBody:
      "シニアソフトウェアエンジニアです。コーディングインタビューを「する側」として担当したことがありません。面接する側も準備が必要なため、仕事量が増えるイメージですが、どういう経緯orどういう人が面接官として任されるのでしょうか？",
    company: "テック 外資系企業",
    tags: ["#キャリア相談", "#ソフトウェアエンジニア"],
    commentCount: 5,
    howManyDaysAgo: 1,
    link: "https://workcircle.app/",
    author: "713430q8p"
  },
  {
    title: "海外就職（ヨーロッパ）への道",
    threadBody:
      "文系出身のコンサル/SIの4年目で、ここ1年半くらいは開発しています。パートナーがドイツに住んでおり、仕事も向こうで見つけて帰ってこない可能性が高く、ヨーロッパ、できればドイツで仕事を見つけたいと思っています。どうやって行くか、悩んでいます。ビジネスサイドでやって行く自信も気持ちもなく、開発の方が好きですし、海外でもやって行きやすいのではないかと思っています。思いつくのは、開発部署に移りエンジニアとしての職歴を2, 3年に伸ばし、その間にアルゴリズムやドメイン知識を勉強して、エンジニアとしてヨーロッパの企業を受けるヨーロッパのCSの大学院で入れてくれるところを探して学位を取って就活するいっそワーホリビザを取って、フリーランスしながら向こうで職を探す",
    company: "SI 企業",
    tags: ["#キャリア相談", "#転職活動"],
    commentCount: 7,
    howManyDaysAgo: 3,
    link: "https://workcircle.app/",
    author: "7kdu9yd"
  },
  {
    title: "テック企業の確定拠出年金(退職金)について共有しましょう",
    threadBody:
      "テック企業は日系大企業のような退職金がない会社多いですよね。私の会社だと確定拠出年金が採用されていますが、基本給の2%が会社から毎月積み立てられます(少ない、、、)。皆さんの会社はどうですか？",
    company: "テック 外資系企業",
    tags: ["#テック", "#給与"],
    commentCount: 12,
    howManyDaysAgo: 1,
    link: "https://workcircle.app/",
    author: "713430q8p"
  },
  {
    title: "新卒からずっと同じ会社にいる人",
    threadBody:
      "学生時代、就職活動をしていた時、「ここの会社は30歳で年収1,000万超えるor超えない」という話題をよく周りとしていたな、とふと思い出しました。皆さんの30歳の時の年収はどれくらいでしたでしょうか？自分は今29歳ですが、750~800万くらいです。30歳で1,000万目標は超えそうにありません...",
    company: "テック 企業",
    tags: ["#キャリア"],
    commentCount: 21,
    howManyDaysAgo: 6,
    link: "https://workcircle.app/",
    author: "8732hnd0"
  },
  {
    title: "エンジニアのキャリアパス",
    threadBody:
      "私はジョブホッパーとまでは行かないですが8年のキャリアで4回転職しています。同じ会社に長い期間居残れる人はすごいとは思いつつ、他の会社に興味もってるのかなと素朴な質問です。",
    company: "テック 外資系企業",
    tags: ["#キャリア", "#転職活動"],
    commentCount: 29,
    howManyDaysAgo: 4,
    link: "https://workcircle.app/",
    author: "713430q8p"
  },
  {
    title: "Gにてビジネス職やってます、質問あれば答えます。",
    threadBody: "私が答えられる範囲でお答えしますのでなんなりと",
    company: "テック 外資系企業",
    tags: ["#仕事雑談"],
    commentCount: 4,
    howManyDaysAgo: 2,
    link: "https://workcircle.app/",
    author: "jahdf0986"
  },
  {
    title: "モテる職種は？",
    threadBody:
      "主観で大丈夫です！自分は、プロダクトマネージャー押しです。理由は、面白い考えを持っていたり、物事に固執しない人が多いなぁ〜と思うから。(自分の彼氏はフルスタッフエンジニアですw)",
    company: "テック 外資系企業",
    tags: ["#仕事雑談"],
    commentCount: 10,
    howManyDaysAgo: 7,
    link: "https://workcircle.app/",
    author: "7879diif"
  },
  {
    title: "社内コミュニケーションが完全に英語の外資は?",
    threadBody:
      "日本人の社員どうしは日本語で雑談するのは当然ですが、基本の社内のコミュニケーションが全て英語の外資、および全て英語の部署をご存知でしたら教えて下さい。例えば...日本支社はあっても、日本からAPACやその他のリージョンの営業、マーケットを担当しているから基本が英語など。もしくは完全に日本支社がなく、independent contractorやEORで雇っている会社など。日本語寄りよりも英語寄りの社内で働くのが好きなので、情報収集中で(自分の彼氏はフルスタッフエンジニアですw)",
    company: "テック 外資系企業",
    tags: ["#転職", "#外資系"],
    commentCount: 8,
    howManyDaysAgo: 8,
    link: "https://workcircle.app/",
    author: "7jashfkj"
  },
  {
    title: "副業の単価どれくらい？?",
    threadBody:
      "みんながどれくらい儲けてるのか気になりました。フロントエンドの副業で今は時給6,000円でやってます。",
    company: "テック 外資系企業",
    tags: ["#副業"],
    commentCount: 8,
    howManyDaysAgo: 9,
    link: "https://workcircle.app/",
    author: "jkashfu990"
  }
];
