<template>
  <div id="banner">
    <v-container white--text>
      <v-row justify-start>
        <v-col xs12 md9 offset-md1>
          <div
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'banner__header'
                : 'banner__header_small'
            "
            class="font-weight-thin accent--text"
          >
            <slot name="header"></slot>
          </div>
          <h1 class="text-h6 font-weight-bold">
            <slot name="header-caption"></slot>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="6" md="4">
          <v-btn
            x-large
            elevation="10"
            rounded
            color="secondary"
            :to="{ path: locale.base + '/submit-compensation-selection' }"
            dark
          >
            <v-icon left dark large>mdi-plus</v-icon>
            {{ $t("add_compensation") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "add_compensation": "Add your compensation"
  },
  "ja": {
    "add_compensation": "年収を追加"
  }
}
</i18n>
<script>
import store from "store";
export default {
  name: "Banner",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    locale() {
      return store.getters.locale;
    }
  }
};
</script>

<style lang="scss" scoped>
#banner {
  background-color: var(--v-primary-base);

  .banner__header {
    font-size: 48px;
    font-family: "Bebas Neue";
  }

  .banner__header_small {
    font-size: 36px;
    font-family: "Bebas Neue";
  }
}
</style>
