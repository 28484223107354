<template>
  <div v-if="jobRole">
    <banner v-show="$vuetify.breakpoint.smAndUp">
      <template slot="header">
        {{
          $t("headline", {
            job_role: jobRole.name
          })
        }}
      </template>
      <template slot="header-caption">
        {{ $t("subheadline") }}
      </template>
    </banner>
    <hr class="yellow-rule" />
    <v-container v-show="roles">
      <!-- Tabs for roles -->
      <v-row class="mt-sm-2">
        <v-col>
          <v-tabs center-active color="secondary" show-arrows="mobile">
            <v-tab
              v-for="role in roles"
              :key="role.id"
              :to="
                $i18nRoute({
                  name: 'roleShow',
                  params: { slug: role.slug }
                })
              "
              replace
            >
              {{ role.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12">
          <h1 class="text-h6 text-sm-h4 font-weight-bold primary--text">
            {{ $t("featured_companies") }}
          </h1>
        </v-col>
        <v-col
          v-for="company in featuredCompanies"
          :key="company.slug"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            class="company-card d-flex flex-column"
            :to="$i18nRoute(companyPath(company))"
            height="100%"
          >
            <v-card-title
              class="text-subtitle-1 secondary--text font-weight-bold flex-grow-1 align-start"
            >
              {{ company.name }}
            </v-card-title>
            <v-card-subtitle>
              <div class="d-block">
                <span class="mr-3">{{ $t("median") }}</span>
                <span class="text-subtitle-1 font-weight-bold primary--text">{{
                  displayCompensationItem(company.median)
                }}</span>
              </div>
              <div class="d-block">
                <span class="mr-3">{{ $t("compensation_entries") }}</span>
                <span class="font-weight-bold primary--text">{{
                  company.entries
                }}</span>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <router-link
            :to="
              $i18nRoute({
                name: 'companyListing',
                query: { role: role_slug }
              })
            "
            class="text-subtitle-2"
          >
            {{ $t("see_companies") }}
            <v-icon class="ml-0" x-small color="secondary" right>
              mdi-arrow-right
            </v-icon>
          </router-link>
        </v-col>
      </v-row>
      <template v-if="showWorkCircleCards">
        <v-row v-if="locale.code == 'ja'">
          <v-col cols="12">
            <h1>{{ $t("posts_in_workcircle") }}</h1>
            <h3 class="text-subtitle-2 mr-2">
              {{ $t("subtitle_for_workcircle") }}
              <a
                href="https://www.workcircle.app/?ref=opensalary"
                class="text-subtitle-2"
              >
                {{ $t("join_work_circle") }}
                <v-icon class="ml-0" x-small color="secondary" right>
                  mdi-arrow-right
                </v-icon>
              </a>
            </h3>
          </v-col>
          <v-col cols="12" class="overflow-x-auto d-flex mb-4">
            <work-circle-card
              v-for="thread in workCircleThreads"
              :key="thread.title"
              v-bind="thread"
            />
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 font-weight-bold primary--text">
            {{ $t("explore_salaries") }}
          </h1>
          <h2 class="text-subtitle-1 primary--text">
            {{ $t("explore_salaries_explanation") }}
            <router-link
              :to="
                $i18nRoute({
                  name: 'exploreSalaries',
                  query: {
                    role: role_slug
                  }
                })
              "
              class="text-subtitle-2 companies"
            >
              <i18n path="see_all_salaries">
                <template v-slot:salary_count>
                  <span>{{ salariesCount }}</span>
                </template>
              </i18n>
              <v-icon class="ml-0" x-small color="secondary" right>
                mdi-arrow-right
              </v-icon>
            </router-link>
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <salary-table
            :salaries="salaries"
            :loading="loading"
            hide-default-footer
            :options="{ itemsPerPage: 20 }"
          />
        </v-col>
      </v-row>
      <!-- Link to explore salaries -->
      <v-row class="mb-4">
        <v-col cols="12" class="d-flex justify-end">
          <h2 class="text-subtitle-1 primary--text">
            <router-link
              :to="
                $i18nRoute({
                  name: 'exploreSalaries',
                  query: {
                    role: role_slug
                  }
                })
              "
              class="text-subtitle-2 companies"
            >
              <i18n path="see_all_salaries">
                <template v-slot:salary_count>
                  <span>{{ salariesCount }}</span>
                </template>
              </i18n>
              <v-icon class="ml-0" x-small color="secondary" right>
                mdi-arrow-right
              </v-icon>
            </router-link>
          </h2>
        </v-col>
      </v-row>
      <!-- Heading for year of experience graph table -->
      <v-row v-if="onSoftwareEngineer">
        <v-col cols="12">
          <h1 class="text-h4 font-weight-bold primary--text">
            {{ $t("view_by_yoe") }}
          </h1>
        </v-col>
      </v-row>
      <!-- Data table for year of experience -->
      <v-row v-if="salariesGroupedByYear && onSoftwareEngineer">
        <v-col cols="12">
          <v-data-table
            :headers="yearsOfExperienceHeaders"
            :items="salariesGroupedByYear"
            :loading="loading"
            :options="{ itemsPerPage: 6 }"
            hide-default-footer
          >
            <template v-slot:item.yearsOfExperience="{ item }">
              <div>
                <div class="text-h6 font-weight-bold">
                  {{ item.yearsOfExperience }}
                </div>
              </div>
            </template>
            <template v-slot:item.salaries="{ item }">
              <horizontal-scatter-chart
                :height="$vuetify.breakpoint.mobile ? 45 : 15"
                :width="$vuetify.breakpoint.mobile ? 260 : 100"
                :min="1500000"
                :max="30000000"
                :dataset="item.salaries"
                :locale="locale.code"
              >
              </horizontal-scatter-chart>
            </template>
            <template v-slot:item.firstQuartile="{ item }">
              {{ displayCompensationItem(item.firstQuartile) }}
            </template>
            <template v-slot:item.median="{ item }">
              <div class="font-weight-bold">
                {{ displayCompensationItem(item.median) }}
              </div>
            </template>
            <template v-slot:item.thirdQuartile="{ item }">
              {{ displayCompensationItem(item.thirdQuartile) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- Link to years of experience -->
      <v-row class="mb-4" v-if="onSoftwareEngineer">
        <v-col cols="12" class="d-flex justify-end">
          <h2 class="text-subtitle-1 primary--text">
            <router-link
              :to="
                $i18nRoute({
                  name: 'yearsOfExperience',
                  query: {
                    role: $route.params.slug
                  }
                })
              "
              class="text-subtitle-2 companies"
            >
              {{ $t("see_all_years") }}
              <v-icon class="ml-0" x-small color="secondary" right>
                mdi-arrow-right
              </v-icon>
            </router-link>
          </h2>
        </v-col>
      </v-row>
      <v-row class="mb-4" justify="center">
        <h2>{{ $t("call_to_add") }}</h2>
      </v-row>
      <v-row justify="center" class="mb-8">
        <v-btn
          color="secondary"
          :to="$i18nRoute({ name: 'submitCompensationSelection' })"
          large
          dark
          rounded
        >
          <v-icon left dark large>mdi-plus</v-icon>
          {{ $t("add_compensation") }}
        </v-btn>
      </v-row>
      <v-row>
        <v-sheet
          class="mx-auto"
          max-width="600"
          outlined
          color="grey lighten-3"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                <v-icon large color="grey">
                  mdi-email
                </v-icon>
                {{ $t("subscribe") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("subscribe_subtext") }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-col>
              <v-row>
                <v-text-field
                  v-model="email"
                  placeholder="iamdev@gmail.com"
                  class="mr-1"
                  type="email"
                  outlined
                  clearable
                  dense
                ></v-text-field>
                <v-btn
                  height="40"
                  dark
                  color="primary"
                  depressed
                  @click="createLead"
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-list-item>
        </v-sheet>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="accent"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<i18n>
{
  "en": {
    "page_title": "{job_role} Salaries",
    "headline": "{job_role} Salaries in Japan",
    "subheadline": "Know what you are worth. Made by the community.",
    "call_to_add": "Help others like you, add your own compensation!",
    "add_compensation": "Add your compensation",
    "software_engineer": "Software Engineer",
    "engineering_manager": "Engineering Manager",
    "product_manager": "Product Manager",
    "subscribe": "Subscribe",
    "subscribe_subtext": "Industry insights, career tips and site updates.",
    "submit": "submit",
    "subscribe_success_message": "Got it! We'll keep you informed.",
    "see_companies": "See all companies",
    "see_all_years": "See all years of experience",
    "see_all_salaries": "View all {salary_count} salaries",
    "company_name": "Company",
    "company_name_example": "Google, LINE, etc",
    "explore_salaries": "Explore Salaries",
    "explore_salaries_explanation": "Filter, sort and view our database of salary data with high level statistics as well as individual entries in detail.",
    "featured_companies": "Popular Companies",
    "view_by_yoe": "Compensation by Years of Experience",
    "input_date": "Date",
    "grade": "Grade/Level",
    "role": "Role",
    "focus": "Focus",
    "focus_example": "E.g. iOS",
    "years_of_experience": "Years of Experience",
    "contribute_salaries": "Want to contribute, add yours!",
    "additional_information": "Additional Information",
    "gender": "Gender:",
    "male": "male",
    "female": "female",
    "other": "other",
    "unknown": "unknown",
    "education_level": "Education:",
    "age": "Age:",
    "annual_compensation": "Total Compensation",
    "annual_base_salary": "Base Salary",
    "annual_bonus": "Bonus",
    "annual_stock": "Equity",
    "report_item": "Report something wrong with this entry",
    "report_issue": "Report issue with salary entry",
    "describe_problem": "Describe what is wrong with this entry:",
    "compensation_entries": "Entries",
    "range": "Range",
    "count": "# of Entries",
    "median": "Median",
    "meta_description": "Explore detailed information on over {salary_count_rounded}+ salary entries for {job_role_name}s in Japan from over 300 companies such as Google, LINE, Indeed, Rakuten and Mercari.",
    "salaries": "Salaries",
    "posts_in_workcircle": "Posts in WorkCircle",
    "subtitle_for_workcircle": "These are posts in an anonymous community app called WorkCircle, dedicated to employees of tech companies in Japan.",
    "join_work_circle": "Join the community"
  },
  "ja": {
    "page_title": "{job_role}年収",
    "headline": "{job_role}のリアルな年収データ",
    "subheadline": "投稿されたリアルな年収データをもとに、あなたの市場価値を知ろう",
    "add_compensation": "年収を追加",
    "software_engineer": "ソフトウェアエンジニア",
    "engineering_manager": "エンジニアリングマネージャー",
    "product_manager": "プロダクトマネージャー",
    "subscribe": "メルマガ登録",
    "subscribe_subtext": "業界の考察、キャリアのヒント、サイトの新機能紹介など",
    "subscribe_success_message": "お知らせを配信します！",
    "submit": "登録",
    "call_to_add": "皆さんの情報で、企業の年収をよりオープンに可視化しましょう！",
    "see_companies": "すべての会社を見る",
    "see_all_years": "経験年数を見る",
    "see_all_salaries": "{salary_count}件全てを見る",
    "company_name": "企業名",
    "company_name_example": "Google、メルカリなど",
    "explore_salaries": "給与検索",
    "explore_salaries_explanation": "統計された給与情報や詳細な個々の給与情報をフィルター、並び替えして見る。",
    "featured_companies": "よく見られている企業",
    "view_by_yoe": "経験年数で見る",
    "input_date": "追加日",
    "grade": "グレード・レベル",
    "focus": "フォーカス",
    "focus_example": "iOSなど",
    "years_of_experience": "経験年数",
    "contribute_salaries": "あなたの情報も提供してエンジニアのパワーアップに貢献しましょう!",
    "additional_information": "補足情報",
    "gender": "性別：",
    "male": "男性",
    "female": "女性",
    "other": "その他",
    "unknown": "不明",
    "education_level": "最終学歴:",
    "age": "年齢：",
    "annual_compensation": "年収",
    "annual_base_salary": "基本給(年)",
    "annual_bonus": "賞与(年)",
    "annual_stock": "株式報酬(年)",
    "report_item": "問題を報告する",
    "report_issue": "年収情報の誤りを報告する",
    "describe_problem": "通報内容をご記入ください：",
    "compensation_entries": "件",
    "median": "中央値",
    "range": "年収範囲",
    "count": "件",
    "meta_description": "Google、LINE、Indeed、楽天、メルカリなど300社を超えるデータから{salary_count_rounded}件以上の{job_role_name}の給与情報を検索。",
    "salaries": "年収",
    "posts_in_workcircle": "WorkCircleの投稿",
    "subtitle_for_workcircle": "外資系・テック企業社員専用の匿名コミュニティー内の投稿です。",
    "join_work_circle": "コミュニティに参加する"
  }
}
</i18n>

<script>
import _ from "lodash";
import Banner from "components/Banner";
import api from "services/api";
import store from "store";
import SalaryTable from "components/salary_table/SalaryTable.vue";
import WorkCircleCard from "components/WorkCircleCard.vue";
import HorizontalScatterChart from "components/HorizontalScatter.chart.vue";
import statistics from "../utils/statistics";
import { displayCompensationItemMixin } from "../mixins/displayCompensationItemMixin";
import { showWorkCircleCards } from "../constants/featureFlags";
import { workCircleThreads } from "../constants/workCircleThreads";

export default {
  mixins: [displayCompensationItemMixin],
  props: {
    role_slug: {
      type: String,
      default: "software-engineer"
    }
  },
  components: {
    Banner,
    HorizontalScatterChart,
    SalaryTable,
    WorkCircleCard
  },
  data: () => ({
    roles: [],
    companies: [],
    featuredCompanies: [],
    salariesGroupedByYear: [],
    salaries: [],
    salariesCount: null,
    search: {},
    jobRole: "",
    roleFocusList: [
      "iOS",
      "Android",
      "Mobile",
      "Web Frontend",
      "Fullstack",
      "Backend",
      "AI/ML",
      "Site Reliability(SRE)",
      "Security",
      "Devops",
      "Data",
      "Networking"
    ],
    pagination: {
      current_page: 1
    },
    link: true,
    loading: false,
    email: "",
    snackbar: false,
    text: "",
    showWorkCircleCards,
    workCircleThreads
  }),
  created() {
    this.getJobRoles();
    this.getJobRole();
    this.getSalaries();
    this.getFeaturedCompanies();
  },
  watch: {
    $route() {
      this.getJobRole();
      this.getSalaries();
      this.getFeaturedCompanies();
    }
  },
  metaInfo() {
    const description = this.$t("meta_description", {
      job_role_name: this.jobRole.name,
      salary_count_rounded:
        this.salariesCount > 10
          ? _.floor(this.salariesCount, -1)
          : this.salariesCount
    });

    return {
      title: this.$t("page_title", {
        job_role: this.jobRole.name
      }),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: description
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: description
        },
        {
          vmid: "og-title",
          property: "og:title",
          content: description
        },
        {
          vmid: "og-description",
          property: "og:description",
          content: description
        }
      ]
    };
  },
  computed: {
    locale() {
      return store.getters.locale;
    },
    lead() {
      return {
        email: this.email
      };
    },
    yearsOfExperienceHeaders() {
      return [
        {
          text: this.$t("years_of_experience"),
          value: "yearsOfExperience"
        },
        {
          text: this.$t("range"),
          value: "salaries",
          sortable: false
        },
        {
          text: this.$t("25%"),
          value: "firstQuartile"
        },
        {
          text: this.$t("median"),
          value: "median"
        },
        {
          text: this.$t("75%"),
          value: "thirdQuartile"
        },
        {
          text: this.$t("count"),
          value: "count"
        }
      ];
    },
    onSoftwareEngineer() {
      return this.role_slug === "software-engineer";
    }
  },
  methods: {
    getJobRoles() {
      api.getJobRoles().then(resp => {
        this.roles = resp.data.items;
      });
    },
    getJobRole() {
      api.getJobRole(this.role_slug).then(resp => {
        this.jobRole = resp.data;
      });
    },
    getSalaries(page = 1) {
      this.loading = "grey";
      const page_query = { page: page };
      const job_role_query = { job_role: this.role_slug };
      const query = {
        ...page_query,
        ...job_role_query
      };

      api.getSalaries(query).then(
        resp => {
          this.salaries = resp.data.items;
          this.pagination = resp.data.meta;
          this.salariesCount = resp.data.items.length;
          this.salariesGroupedByYear = this.groupSalaries(this.salaries);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    },
    createLead() {
      api.createLead(this.lead).then(
        () => {
          this.text = this.$t("subscribe_success_message");
          this.email = "";
          this.snackbar = true;
        },
        error => {
          const errors = error.response.data.email.join("; ");
          this.text = "Email address " + errors;
          this.snackbar = true;
        }
      );
    },
    groupSalaries(salaries) {
      const salariesByExperience = _(salaries)
        .groupBy("years_of_experience")
        .map((items, yearsOfExperience) => ({
          yearsOfExperience,
          salaries: _.map(items),
          median: statistics.quantile(_.map(items, "annual_compensation"), 50),
          firstQuartile: statistics.quantile(
            _.map(items, "annual_compensation"),
            25
          ),
          thirdQuartile: statistics.quantile(
            _.map(items, "annual_compensation"),
            75
          ),
          count: items.length
        }))
        .value();

      return salariesByExperience;
    },
    getFeaturedCompanies() {
      this.loading = "grey";
      const query = { job_role: this.role_slug };
      api.getFeaturedCompanies(query).then(resp => {
        this.featuredCompanies = resp.data.items;
      });
    },
    companyPath(company) {
      return {
        name: "showCompany",
        params: {
          company_slug: company.slug,
          role_slug: this.role_slug
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.no-active-overlay::before {
  opacity: 0 !important;
}
.company-card {
  &:hover {
    background-color: var(--v-gray_lighter-base);
  }
}
</style>
