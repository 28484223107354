<script>
import { Scatter } from "vue-chartjs";

export default {
  extends: Scatter,
  props: {
    dataset: Array,
    label: String,
    min: Number,
    max: Number,
    locale: String
  },
  mounted() {
    this.renderScatterPlot();
  },
  methods: {
    displayValueManEn(value) {
      const valueInManEn = Math.round(value / 10000);
      return `${valueInManEn.toLocaleString()}万円`;
    },
    displayValueMillions(value) {
      const valueInMillions = Math.round(value / 1000000);
      return `${valueInMillions.toLocaleString()}M`;
    },
    formatData(rawData) {
      const arr = rawData.map(val => {
        return { ...val, x: val.annual_compensation, y: " " };
      });
      return arr;
    },
    renderScatterPlot() {
      this.renderChart(
        {
          labels: ["", " ", "  "],
          datasets: [
            {
              data: this.formatData(this.dataset),
              pointRadius: 4,
              borderColor: "rgba(255, 146, 51, 1)"
            }
          ]
        },
        {
          legend: {
            display: false
          },
          tooltips: {
            mode: "single",
            callbacks: {
              title: (items, data) => {
                const compensation =
                  data.datasets[items[0].datasetIndex].data[items[0].index]
                    .annual_compensation;
                if (this.locale === "ja") {
                  return this.displayValueManEn(compensation);
                } else {
                  return this.displayValueMillions(compensation);
                }
              }
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawBorder: false
                },
                type: "linear",
                position: "bottom",
                ticks: {
                  min: this.min,
                  max: this.max,
                  callback: value => {
                    if (this.locale === "ja") {
                      return this.displayValueManEn(value);
                    } else {
                      return this.displayValueMillions(value);
                    }
                  }
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                  zeroLineColor: "transparent"
                },
                type: "category",
                position: "left"
              }
            ]
          }
        }
      );
    }
  },
  watch: {
    dataset: function() {
      this.renderScatterPlot();
    }
  }
};
</script>
