<template>
  <v-card
    class="pa-sm-2 pa-0 flex-shrink-0 d-flex flex-column mr-4 workcircle--text"
    width="360"
    variant="outlined"
  >
    <v-card-text class="d-flex flex-column flex-grow-1">
      <div class="d-flex">
        <span v-for="tag in tags" class="grey--text card-head mr-1" :key="tag">
          {{ tag }}
        </span>
      </div>
      <div class="d-flex text-subtitle-2 card-head workcircle-secondary--text">
        <span class="font-weight-bold">
          {{ company }}
        </span>
        <v-icon small>mdi-circle-small</v-icon>
        <span>
          {{ author }}
        </span>
        <v-icon small>mdi-circle-small</v-icon>
        <span> {{ howManyDaysAgo }}日前 </span>
      </div>

      <span class="workcircle--text card-title mt-1">
        {{ title }}
      </span>
      <span class=" workcircle-sub--text card-content flex-grow-1 mt-1">
        {{ truncateText(threadBody) }}
      </span>
      <v-spacer></v-spacer>
      <div class="text-caption mt-2">
        <span class="mr-2">
          <v-icon color="grey" size="20">mdi-thumb-up</v-icon>
          35
        </span>
        <v-icon color="grey" size="20" class="mr-4">mdi-thumb-down</v-icon>
        <span class="mr-2">
          <v-badge dot overlap color="#FE8A41">
            <v-icon color="grey" size="20">mdi-message-reply-outline</v-icon>
          </v-badge>
          {{ commentCount }}
        </span>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center flex-grow-0 mb-2">
      <v-btn
        rounded
        color="#FE8A41"
        class="white--text font-weight-bold"
        min-width="200"
        :href="link"
      >
        {{ $t("read_more") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<i18n>
  {
    "en": {
      "read_more": "Read more"
    },
    "ja": {
      "read_more": "続きを読む"
    }
  }
</i18n>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.card-head {
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
}

.card-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .card-head {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .card-content {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .card-title {
    font-size: 14px !important;
    line-height: 24px !important;
  }
}

.workcircle--text {
  color: #222b45 !important;
}
.workcircle-sub--text {
  color: #495063 !important;
}

.workcircle-secondary--text {
  color: #8992a3 !important;
}
</style>

<script>
export default {
  props: {
    thread: Object,
    title: String,
    threadBody: String,
    company: String,
    tags: Array,
    commentCount: Number,
    howManyDaysAgo: Number,
    link: String,
    author: String
  },
  methods: {
    truncateText(text) {
      if (text.length <= 50) {
        return text;
      }

      return text.slice(0, 50) + "...";
    }
  }
};
</script>
